.signup_clinic{
  .ant-form-item{
    margin-bottom: 1rem;
    .ant-form-item-control-input-content{
      .ant-input-affix-wrapper{
          @extend .comman_input-syle;
          padding: 0 !important;
          position: relative;
          input{
            padding: 10px;
            border-radius: 1rem;
            &:focus{
              border: none;
            }
          }
          .ant-input-suffix{
            position: absolute;
            right: 10px;
            top: 52%;
            transform: translate(0, -50%);
          }
          &:focus{
            box-shadow: none;
          }
      }
    }
    .ant-form-item-label  {
      padding-bottom: 0 !important;
      label {
        margin-bottom: 0.5rem;
        display: block;
        font-weight: 600;
        color: #595959;
      }
    }
  }
}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
  border-right-width: 1px !important;
}

.ant-form-item-explain-error{
  padding-top: 2px;
}
.ant-input-filed{
  .ant-form-item-control-input-content{
    input{
     @extend .comman_input-syle;
    }
  }
}
.resendcode_popup{
  max-width: 410px;
  width: 100%;
  margin: 0 auto;
  .custom_modal{
    padding: 0;
  }
  .ant-modal-body {
    padding: 23px 42px 41px 42px !important;
  }
  h3{
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    @extend .montserrat_bold;
    margin-bottom: 16px;
  }
  .otpMsg {
    p.notice{
      font-family: "raleway_medium",sans-serif;
    }
    .otp_error-msg{
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #C00C2D;
      font-family: raleway_medium;
      padding-top: 12px;
      @include width(230px);
      margin: 0 auto;
    }
    p.notice, p.resend_code_msg{
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #495568;
      max-width: 252px;
      width: 100%;
      margin: 0 auto 21px;
    }
    .form_row{
      justify-content: center;
      @media (max-width: 600px){
        & {
          flex-direction: unset;
          .verification_otp{
            margin-bottom: 20px;
          }
        }
      }
      .ReactInputVerificationCode__container {
        max-width: 240px; 
        width: 100%;
        margin-bottom: 26px;
    }
      .ReactInputVerificationCode__item {
        max-width: 40px;
        width: 100%;
        height: 58px;
        background: #E5E5E5;
        border-radius: 5px;
        // box-shadow: none !important;
        padding: 5px;
        margin: 4px;
        font-weight: 600;
        font-size: 40px;
        line-height: 42px;
        color: #000000;
        @extend .montserrat_bold;
        @include flex;
    }
  }
  p.resendcode_msg{
    @extend .montserrat_regular;
    margin: 0 !important;
    max-width: unset;
    text-align: center;
    cursor: pointer;
  }
  }
  .resendcode_btn{
    margin: 26px 0 0;
  }
}
.errorMsg {
  padding-top: 2px;
  font-family: montserrat_medium;
  text-transform: capitalize;
  font-weight: 600;
  color: $red;
  font-size: 11px;
}

.switch_primary_box{
  .ant-form-item-control-input-content{
    .ant-input-password{
      padding: 0 !important;
      width: 328px !important;
      box-sizing: border-box !important;
      border-radius: 10px !important;
  }
  }
  
input {
  padding: 11px 10px;
  background: #F8F8F8;
  width: 319px !important;
  border: 0 !important;
  border-radius: 10px;
}
.ant-input-suffix {
  margin-left: 4px;
  margin-right: 10px;
}
}
.sigin_banner{
  input{
    font-weight: 400;
    font-family: montserrat_medium;
    color: #3E4A58 !important;
    // padding: 11px 10px;
    &::placeholder{
      font-family: montserrat_medium;
      font-weight: 400;
    }
  }
}
.comman_input-syle{
  border: 1.5px solid #DEDEDE !important;
  min-height: 4.2rem;
  width: 100%;
  padding: 10px 10px 11px;
  border-radius: 1rem;
  line-height: 10px;
}
.signup_block.sign_box .admin_sigin{
  padding: 32px 52px 44px;
  @media (max-width: 991px) {
    padding: 20px;
}
input{
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3125px;
  color: #3E4A58;
  font-family: montserrat_medium;
  font-weight: 500;
  &:focus{
      background: #E7E7E7;
      border: 1.5px solid #DEDEDE;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px #E7E7E7 inset !important;
  }

}
}
.sigin_banner.signup_banner_block{
  padding-bottom: 80px;
}

.form_row {
  .verification_otp {
    margin-bottom: 26px;
    max-width: 240px;
    width: 100%;
    .styles_react-code-input-container__tpiKG{
      max-width: 240px !important;
    }
    .styles_react-code-input__CRulA{
      display: flex;

      input{
        align-items: center;
        background: #e5e5e5;
        border-radius: 5px;
        color: #000;
        display: flex;
        font-size: 40px;
        font-weight: 600;
        height: 58px;
        justify-content: space-between;
        line-height: 42px;
        margin: 4px;
        max-width: 40px;
        padding: 0;
        width: 100%;
        border: none;
        font-family: 'montserrat_bold';
        &::selection{
          background: unset;
          color: #000;
        }
        &:focus {
          outline: none;
          border: 2.5px solid #1784e9 !important;
          caret-color: #000;
        }
      }
    }

  }
}