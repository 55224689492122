.schedulepatient{
  width: calc( 100% - 250px);
  .schedulepatient_nav{
    background: rgba(154, 176, 189, 0.19);
    @extend .d_flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 52px 7px 46px;
    @include media1600 {
      padding: 7px 52px 7px 38px;
    }

    @media (max-width: 1500px){
      padding: 14px 20px 7px;
    }
    .searchbar{
      position: relative;
      img{
        position: relative;
        top: auto;
        left: 35px;
      }
      #search{
        max-width: 310px !important;
        width: 100% !important;
        min-width: 310px;
        background-color: #FFFFFF;
        padding: 9px 20px 10px 32px;
        display: block;
        min-height: auto !important;
        @extend .radius_20;
        color: #a19c9c;
        letter-spacing: 0.7px;
        @extend .comman_input_scss;
        border-width: 2px;
        &::placeholder{
          color: $gray_place;
          @extend .montserrat_medium;
        }
        @media (max-width: 768px) {
          &{
            min-width: unset;
          }
        }
      }
      &::before{
        content: url(../images/Search_icon.png);
        position: absolute;
        top: 20px;
        left: 16px;
      }
    }
    .add_appoinment{
      .add_appoinment_btn{
        max-width: 212px;
        min-width: 212px;
        width: 100%;
        font-family: montserrat_medium;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.3125px;
        color: $white;
        @include media1600 {
          margin-left: 10px;
        }
        @include media1440 {
          margin-left: 0px;
        }
      }
    }
    .clinicName{
      line-height: 1.4;
      display: block;
      @extend .text_right;
      @include media1440 {
        margin-right: 20px;
      }
      a{
        display: block;
        max-width: 200px;
        width: 100%;
        @media (max-width: 1200px) {
          max-width: 250px;
          text-align: left;
        }
      }
      .clinicName_text{
        @extend .montserrat_medium;
        color: $text_blue;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.3125px;
      }
      @include media1140 {
        margin-right: 20px;
        text-align: left;
      }

    }
  }
  .schedule_appoinment{
    @media (min-width: 1380px) {
      height: calc(100% - 76px);
    }
    // @media (max-width: 1024px) {
    //     flex-direction: column;
    // }
    @extend .d_flex;

    .schedule_appoinment--block1{
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      transition: all 0.3s;
      @media (max-width: 1200px) {
        &.false{
          width: 100%;
        }
        &.true{
          width: 60%;
        }
      }
      @media (max-width: 1024px) {
        width: 100%;
        margin-bottom: 20px;
        min-height: 160px;
      }
      .schedule_appoinment_thead{
        position: sticky;
        top: 0;
        background: #FFFFFF;
      }
      .pagination-container {
        align-self: flex-end;
      }
      .prev_appoinment{
        overflow: auto;
        width: 100%;
        align-self: flex-start;
        &::-webkit-scrollbar {height: .5rem; width: .5rem;}
        @include comman_scrollbar_firefox(#3E4A58, #BFC6CF);
        .patient_prev_appoinment{
          width: 100%;
          border-collapse: collapse;

          .patient_prev_appoinment_head{
            background: rgba(168, 171, 224, 0.37);
            th {
              vertical-align: top;
              padding: 13px 20px 10px 10px;
              &:first-child{
                width: 7%;
                padding-left: 45px;
                @media (max-width: 1500px) {
                  padding-left: 10px;
                  width: 20%;
                }
              }
              &:nth-child(2), &:nth-child(3){
                width: 6%;
                @media (max-width: 1500px) {
                  width: 20%;
                }
              }
              &:nth-child(4){
                width: 14%;
                @media (max-width: 1500px) {
                  width: 10%;
                }
              }
              @media (max-width: 1400px) {
                &{
                  font-size: 12px !important;
                }
              }
            }
            .date_head{
              @extend .comman_th_style;
              min-width: 190px;
            }
            .patient_head{
              @extend .comman_th_style;
              min-width: 130px;
            }
            .email_head{
              @extend .comman_th_style;
              min-width: 220px;
            }
            .minutes_head{
              text-align: right;
              padding-right: 26px;
              min-width: 250px;
            }
          }
          .td_sp {
            padding: 28px 20px 10px 48px !important;
            vertical-align: baseline;
            @media (max-width: 1500px) {
              padding-left: 10px !important;
            }
          }
          .date_time{
            .date{
              color: rgba(62, 74, 88, 0.5);
              @extend .montserrat_medium;
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0.3125px;
              margin: 0;
            }
            .time{
              @extend .date;
              font-family: montserrat_bold;
              font-weight: 700;
            }

          }
          .patient_name_div{
            .patient_name{
              font-family: montserrat_medium;
              color: $text_think;
              font-weight: 500;
              font-size: 13px;
            }
            .send_messege{
              margin: 3px 0 0;
              font-family: montserrat_bold;
              font-weight: 500;
              font-size: 11px;
              line-height: 13px;
              text-transform: uppercase;
              color: $maroon;
              cursor: pointer ;
            }
          }
          .patient_email_div{
            .patient_email,p{
              margin: 0;
              @extend .montserrat_medium;
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0.3125px;
              color: $text_think;
            }
          }
          .cancel_changes_div{
            padding: 20px 17px 5px 10px !important;
            text-align: right;
            .await_schedule_wrap {
              position: absolute;
              top: 0;
              right: 26px;
              .await_wrap {
                display: inline-block;
                vertical-align: top;
                font-size: 11px;
                line-height: 13px;
                text-align: right;
                color: #3E4A58;
                padding: 2px 7px 0 0;
                font-family: raleway_bold;
                font-weight: 500;
              }
              .schedulePatients_status {
                position: static;
                width: auto;
                display: inline-block;
                vertical-align: top;
              }
            }
            .cancel_changes{
              display: inline;
              color: $maroon;
              cursor: pointer;
              font-family: raleway_medium;
              font-weight: 600;
              font-size: 11px;
              line-height: 13px;
              text-transform: uppercase;
              margin-left: 10px;
              &:first-child{
                padding-right: 10px;
              }
            }
          }
          .main_appoinment-block {
            padding: 0 0 12px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .cancel_changes_div {
              padding-top: 0 !important;
            }
          }
          .patient_text_wrap{

            // min-height: 50px;
            &.blank_div{
              min-height: unset;
              padding: 5px 0 10px;
            }

            .content_block{
              margin: 0px 0 0px 48px;

              .date_{
                font-family: montserrat_bold;
                font-weight: bold;
                font-size: 11px;
                line-height: 13px;
                text-transform: uppercase;
                color: #3E4A58;
                padding-bottom: 6px;
              }
              @media (max-width: 1500px) {
                margin-left: 10px !important;
              }
            }

            .startbtn_div{
              margin-top: -5px;
              padding: 0 26px 0 70px;
            }
          }
        }
      }
      .startbtn_div{
        display: block;
        .start{
          margin: 20px 0 22px auto;
        }
      }
    }
    .main_cancle_chngs_block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .upcoming_appoinment_databox{
      .tr_start_btn{
        padding: 0 10px 36px 28px;
      }
      tr.patient_info{
        &:not(:first-child){
          border-top: 1px solid $gray_place;
        }
      }
    }
    .patient_prev_appoinment{
      .tr_sp_details{
          border-top: 1px solid #DDDDDD;
        td{
          padding: 20px 10px 10px;
        }
      }
      tbody{
        //border-bottom: 1px solid #C4C4C4;
      }
    }

    .start{
      max-width: 110px;
      border-radius: 30px;
      @extend .montserrat_bold;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-transform: uppercase;
      min-width: 110px !important;
      width: 100%;
      display: block;
      padding: 10px !important;
      margin: 5px;
    }
    .tr_start_btn button.btn.btn_primary.start {
      margin: 0;
    }
    .schedule_appoinment--block2{
      transition: all 0.3s;
      width: 40%;
      @media (max-width: 1200px) {
        &.false{
          transform: translateX(100%);
          width: 0;
        }
        &.true{
          transform: translateX(0);
        }
      }
      // @media (max-width: 1024px) {
      //     width: 100%;
      // }
      background: #F8F8F8;
      .calander{
        width: 100%;
        height: 385px;
        padding: 32px 50px 20px 56px;
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;
        @include comman_scrollbar_firefox(#3E4A58, #BFC6CF);
        .time {
          display: block;
          text-align: center;
          font-size: 14px;
          line-height: 20px;
          color: #45809D;
          font-family: "montserrat_medium",sans-serif;
          font-weight: 500;
          margin: 10px 0 0;
          padding-bottom: 5px;
        }
        .date_picker_block{
          max-width: 274px;
          width: 100%;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .check_appoinemt_block{
          width: calc(100% - 274px);
          padding: 45px 0 0 25px;
        }

        @media (max-width: 1670px) {
          &{
            padding: 28px 20px 20px;
            .check_appoinemt_block{
              padding: 45px 0 0 10px;
            }
          }
        }
        @media (max-width: 1300px) {
          &{
            flex-direction: column-reverse;
            overflow-x: auto;
            &::-webkit-scrollbar {
              height: 5px;
            }
            .check_appoinemt_block{
              width: 100%;
              padding: 0 0 10px 10px;
            }
            .date_picker_block{
              max-width: 100%;
              margin-right: 0;
            }
          }
        }
        @media (max-width: 1024px) {
          &{
            flex-direction: column-reverse;
          }
        }
        @media (max-width: 700px) {
          &{
            flex-direction: column-reverse;
            .check_appoinemt_block{
              width: 100%;
              padding: 0 0 10px 10px;
            }
            .date_picker_block{
              max-width: 100%;
              margin-right: 0;
            }
          }
        }
        .pending, .confirmed{
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          text-transform: capitalize;
          color: #000000;
          font-family: montserrat_semibold;
          font-weight: 500;
          padding-left: 15px;
          position: relative;
          &::before{
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            background: #DB4E4E;
            border-radius: 100%;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
        .pending{
          padding-bottom: 2px;
        }
        .confirmed{
          &::before{
            background-color: #64BEE4;
          }
        }
        .react-datepicker__week{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 6px 0;
        }
        .react-datepicker__header, .react-datepicker{
          background-color: unset;
          border: none;
          border-radius: unset;
        }
        .react-datepicker__month-container {
          position: unset;
          max-width: 274px;
          width: 100%;
          width: 275px;
        }
        .react-datepicker__day-name, .react-datepicker__time-name{
          max-width: 35px !important;
          width: 100% !important;
        }
        .react-datepicker__current-month{
          font-family: raleway_bold;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 18px;
        }
        .react-datepicker__day-name{
          font-weight: 400;
          font-family: Gotham_book;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          text-transform: uppercase;
          color: #959595;
        }
        .react-datepicker__day{
          font-weight: 500;
          font-size: 13px;
          text-align: center;
          color: #000000 !important;
          font-family: Gotham_book;
          font-weight: 600;
          position: relative;
          z-index: 9;
          width: 32px;
          display: block;
          flex: 0 0 32px;
          height: 32px;
          min-height: 32px;
          padding: 8px 0;
          margin: 0;
          border-radius: 100%;
          display: inline-block;
          vertical-align: middle;
          // line-height: 2rem;
        }

        .comman_change_bg{
          background-color: #457B9D;
        }
        .react-datepicker__day--today{
          background-color: #457B9D !important;
        }
        .react-datepicker__day.react-datepicker__day--selected{
          color: #fff;
          @extend .comman_change_bg;
          // &::after{
          //     // @include position(absolute);
          //     position: absolute;
          //     content: "";
          //     width: 100%;
          //     height: 100%;
          //     background: #457B9D;
          //     display: inline-block;
          //     left: 50%;
          //     transform: translate(-50%, 0);
          //     z-index: -1;
          //     border-radius: 100%;
          // }

        }

        .react-datepicker__navigation-icon::before{
          border-color: #000 !important;
          border-width: 1px 1px 0 0 !important;
        }
        .react-datepicker__day.react-datepicker__day--selected{
          color: #000;
          // &::after{
          //     background-color: transparent;
          // }
        }
        .react-datepicker__day[tabindex="0"]{
          color: #000;
          @extend .comman_change_bg;
          background-color: #AEE7FF;
          // &::after{
          //     @include position(absolute);
          //     content: "";
          //     width: 32px;
          //     height: 32px;
          //     background-color: #AEE7FF;
          //     left: 50%;
          //     transform: translate(-50%, 0);
          //     z-index: -1;
          //     border-radius: 100%;
          // }
          &.custom_select_tag {
            @extend .comman_change_bg;
            background-color: #457B9D !important;
            // &::after{
            //     background-color: #457B9D !important;
            // }
          }
        }

        .react-datepicker__day[tabindex="-1"]{
          color: #000;
          &::after{
            background-color: #AEE7FF;
          }
          &::before{
            bottom: -3px !important;
          }
          &.react-datepicker__day--today{
            &::before{
              bottom: -8px !important;
            }

          }
        }
        .react-datepicker__day.custom_select_tag, .react-datepicker__day--today{
          color: #fff !important;
          &::after{
            background-color: #457B9D !important;
          }
        }

        .react-datepicker__day[tabindex="0"]{
          &::before{
            bottom: -8px !important;
          }
        }
        .react-datepicker__day.react-datepicker__day--keyboard-selected{
          &::before{
            display: none !important;
          }

        }
        .react-datepicker__day.custom-pending, .react-datepicker__day.custom-confirmed{
          position: relative;
          &::before{
            content: "";
            @include position(absolute);
            top: unset;
            background-color: #DB4E4E;
            width: 5px;
            height: 5px;
            margin: 0 auto;
            border-radius: 100%;
          }
        }
        .react-datepicker__day.custom-Pending-confirmed::before {
          position: absolute;
          content: "";
          background: url(../images/confirm-pending.png) no-repeat;
          background-size: contain;
          width: 5px;
          height: 5px;
          bottom: 0;
          display: block;
          margin: 0 auto;
          left: 0;
          right: 0;
        }
        .react-datepicker__day.custom-confirmed{
          &::before{
            background-color: #64BEE4;
          }
        }
      }
      .schedule_appoinment_list_part{
        display: block;
        background: #f8f8f8;
        .schedule_table_block{
          overflow: auto;
          height: 550px;
          position: relative;
          &::-webkit-scrollbar {height: .5rem; width: .5rem; }
          &::-webkit-scrollbar-thumb {border-radius: 10px; }
          @include comman_scrollbar_firefox(#3E4A58, #BFC6CF);
          @media (max-height: 1080px) {
            height: 370px;
          }
          @media (min-height: 1080px) and (max-height: 1432px) {
            height: 500px;
          }
          @media (min-height: 1432px) {
            height: 790px;
          }
          @media (min-height: 1680px) {
            height: 1030px;
          }
        }
      }

      .schedule_appoinment_list{
        width: 100%;
        border-collapse: collapse;

        .upcoming_schedule_appoinment_list_head{
          text-align: left;
          th{
            position: sticky;
            top: 0;
            background-color: #dfe0f4;
            z-index: 99;
            padding: 13px 20px 10px;
            &:first-child{
              padding-left: 28px !important;
            }
          }
          .upcoming_appoinment_time{
            // width: 20%;
            font-family: montserrat_semibold;
            font-weight: 500;
            color: $black;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3125px;
          }
          .upcoming_appoinment_patient{
            // width: 30%;
            @extend .upcoming_appoinment_time;
          }
          .upcoming_appoinment_email{
            // width: 40%;
            @extend .upcoming_appoinment_time;
          }
          .upcoming_appoinment_time{
            min-width: 150px;
          }
          .upcoming_appoinment_patient{
            min-width: 180px;
          }
        }
        .upcoming_appoinment_databox{
          tr{
            vertical-align: top;
          }
        }
        .timediv{
          padding: 20px 20px 0px 28px;

        }
        .time{
          margin: 0;
          font-family: montserrat_bold;
          font-weight: bold;
          font-size: 11px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          color: $black;
          padding-bottom: 0px;
        }
        .patient_name{
          font-family: montserrat_medium;
          padding: 22px 20px 15px;
          color: $black;
          font-weight: 500;
          font-size: 11px;
          line-height: 20px;
          letter-spacing: 0.3125px;
        }
        .patient_id{
          @extend .patient_name;
          position: relative;
          p{
            margin: 0;
          }
          .pending ,.request_change{
            position: absolute;
            top: 0;
            right: 50px;
            @media (max-width: 1680px){
              right: 37px;
            }
          }
        }
        .patient_text{
          padding: 0px 0 0px 0;
          font-family: montserrat_medium;
          font-weight: 500;
          font-size: 11.5px;
          line-height: 15px;
          color: $black;
          display: inline-block;
          &:empty{
            padding: 0 !important;
            display: none;
          }
          span{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .td_cancel_change{
          padding: 0 0 20px 28px;
        }

        .cancel_change{
          @extend .d_flex;
          color: $maroon;
          text-transform: uppercase;
          padding-bottom: 16px;
          .cancel,.change{
            @extend .raleway_bold;
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 13px;
            cursor: pointer;
            margin: 0;
            &:not(:last-child){
              padding-right: 14px;
            }
          }
        }
        .pending{
          // width: 63px;
          padding: 3px 12px;
          font-family: raleway_medium;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          color: $white;
          background: $dark_silver;
          letter-spacing: 0.9px;
          // margin-right: 54px;
          float: right;
        }
        .requested_change{
          padding: 2px 10px;
          @extend .pending;
          width: 116px;
          background: $maroon;
        }
        .awaiting{
          padding: 0 0 0 28px;
          width: 176px;
          margin-bottom: 18px;
          @extend .raleway_bold;
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          line-height: 13px;
          text-transform: uppercase;
          color: $dark_silver;
        }
        .border_btm{
          border-bottom: 1px solid #C4C4C4;
        }
      }
    }
  }
}
.await_msg {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #3E4A58;
  padding-bottom: 18px;
  &:empty{
    padding: 0;
  }
}

.blue_dot, .red_dot{
  position: relative;
  &::before{
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    background-color: #64BEE4;
    left: -9px;
    right: auto;
    top: 7px;
    bottom: auto;
    border-radius: 100%;
  }
}
.red_dot{
  position: relative;
  &::before{
    background-color: $maroon;
  }
}
.sp_nav_comman {
  padding: 9px 8px 9px 9px;
}
.switch_box{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  // justify-content: space-between;
  max-width: 259px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 226px;
  }
  .switch input {
    display:none;
  }
  .switch {
    display:inline-block;
    max-width:42px;
    width: 100%;
    position:relative;
  }
  .slider {
    max-width: 42px;
    width: 100%;
    height: 20px;
    @include position(absolute);
    border-radius: 20px;
    background: #457B9D;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: visible;
    transition: 0.4s;
  }
  .slider:before {
    position:absolute;
    content:"";
    width:26px;
    height:26px;
    background:#FFFFFF;
    border-radius:100px;
    top: -8px;
    left: -6px;
    -webkit-transform:translateX(-0px);
    transform:translateX(-0px);
    -webkit-transition:.4s;
    transition:.4s;
    -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);              }
  .switch input:checked + .slider:before {
    -webkit-transform:translateX(20px);
    transform:translateX(20px);
  }
  .off, .on{
    color: #959595;
    margin: 0;
    font-family: montserrat_medium;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.6px;
    text-transform: capitalize;
  }
  .off{
    padding-right: 11px;
  }
  .on{
    padding-left: 10px;
  }
  &.confirmed, &.upcoming {
    .off{
      color: $black;
    }
  }
  &.pending, &.archived{
    .on{
      color: $black;
    }
  }

  .switch input:checked ~ .off {
    color:  $black;
  }
  .switch input:checked ~ .on {
    color: $black;
  }
  input[type="checkbox"]:disabled ~ span.slider {
    background: rgb(69 124 158 / 50%);
  }
}
.upcoming_appoinment_databox{
  .comman_width{
    max-width: 500px;
    width: 100%;
  }
}
.comman_th_style{
  text-align: left;
  font-family: montserrat_semibold;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3125px;
  text-transform: capitalize;
  color: $black;
}
.header-clinicName_block{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .clinicName{
    padding-right: 40px;
  }
}
.calander_view{
  display: none;
  position: relative;
  &:hover{
    .tooltip_block{
      display: block;
    }
  }
  img{
    cursor: pointer;
    @include width(21px);
    height: 24px;
    display: inline-block;
    vertical-align: middle;
  }
  @media (max-width: 1200px) {
    display: inline-block;
    margin-left: auto;

  }
  .tooltip_block{
    position: absolute;
    display: none;
    bottom: -22px;
    left: -50px;
    right: 0;
    min-width: 90px;
    max-width: 90px;
    z-index: 999;
    background-color: #000;
    color: #fff;
    font-size: 10px;
    line-height: 12px;
    padding: 8px 5px;
  }
}
.no_data_block {
  padding: 20px 10px;
  line-height: 2rem;
  font-size: 12px;
  color: #C00C2D;
  font-family: "montserrat_medium";
  border-bottom: 0 !important;
  text-transform: unset;
  text-align: left;
  font-weight: 500;
}
.no_data_block.no_appoinment {
  padding: 32px 20px 20px 50px;
}
.clinic_name-block{
  display: block;
  max-width: 200px;
  width: 100%;
  color: #3079EF;
  font-family: "montserrat_medium", sans-serif;
  font-weight: 500;
  font-size: 13px;

}

.appoinment-modal_block {
  padding: 0;
  h4{
    font-family: montserrat_semibold;
    font-weight: 500;
    color: #25282c;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 40px;
    text-align: center;
    text-transform: capitalize;
  }

  .search_icon{
    position: absolute;
    left: 16px;
    top: 46%;
    transform: translate(0, -50%);
  }
}

.schedulepatient_name-block{
  padding-right: 20px;
  align-self: flex-start;
  h5{
    font-family: 'montserrat_regular';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3125px;
    color: #959595;
    padding-bottom: 2px;
  }
  span{
    font-family: 'montserrat_regular';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3125px;
    color: #000000;
  }
  @media (max-width: 1440px) {
    &{
      padding: 0 20px 8px 9px;
    }
  }
}
.no_data_block.no_appoinments_data{
  padding: 32px 20px 20px 45px;
}

.patient_text_div{
  .patient_text{
    max-width: 600px;
    width: 100%;
    margin: 0;
    color: $text_think;
    font-family: montserrat_medium;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.cancel_changes_div{
  position: relative;
}
.schedulePatients_status.request_change {

  padding: 2px 10px;
  @extend .pending;
  background: #c00c2d !important;
}
.schedulepatient .schedule_appoinment .schedule_appoinment--block1 .prev_appoinment .patient_prev_appoinment .cancel_changes_div .await_schedule_wrap .await_wrap {
  color: #3e4a58;
  display: inline-block;
  font-family: raleway_bold;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  padding: 2px 7px 0 0;
  text-align: right;
  vertical-align: top;
}

element.style {
}
.schedulepatient .schedule_appoinment .schedule_appoinment--block1 .prev_appoinment .patient_prev_appoinment .cancel_changes_div .await_schedule_wrap .schedulePatients_status {
  display: inline-block;
  position: static;
  vertical-align: top;
  width: auto;
}
.schedulePatients_status.pending, .schedulePatients_status.request_chng, .schedulepatient .schedule_appoinment .schedule_appoinment--block2 .schedule_appoinment_list .schedulePatients_status.requested_change {
  background-color: #3e4a58;
  color: #fff;
  font-family: raleway_medium;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: .8px;
  line-height: 12px;
  padding: 4px 12px;
  position: absolute;
  right: 26px;
  text-align: center;
  top: 0;
}
.schedulepatient .schedule_appoinment .schedule_appoinment--block1 .prev_appoinment .patient_prev_appoinment .patient_text_wrap {
  min-height: 50px;
  padding: 7px 0 0px;
}
.schedulepatient .schedule_appoinment .schedule_appoinment--block1 .prev_appoinment .patient_prev_appoinment .patient_text_wrap .content_block {
  margin: 0 0 15px 48px;
  @media (max-width: 1500px) {
    margin-left: 10px !important;
  }
}

.schedulepatient .schedule_appoinment .schedule_appoinment--block1 .prev_appoinment .patient_prev_appoinment .patient_text_wrap .content_block .patient_text {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #636363;
  display: -webkit-box;
  font-family: montserrat_medium;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  margin: 0;
  max-width: 600px;
  overflow: hidden;
  width: 100%;
}
.schedulepatient .schedule_appoinment .patient_prev_appoinment .tr_sp_details:not(:first-child), .schedulepatient .schedule_appoinment .upcoming_appoinment_databox tr.patient_info:not(:first-child) {
  border-top: 1px solid #c4c4c4;
}
.date_{
  font-family: montserrat_bold;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #3E4A58;
  padding-bottom: 6px;
}
.patient-notice{
  font-family: 'montserrat_regular';
  font-weight: 400;
  font-size: 11.5px;
  line-height: 20px;
  letter-spacing: 0.3125px;
  color: #959595;

}
.patient_video_text{
  margin-top: 10px;
}
.border_appointment_list{
  border-bottom: 1px solid #DDDDDD;
}