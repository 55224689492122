
.resetpssword_main{
    width: 100%;
    height: 100vh;
    background: url(../images/resetpsw-bg.png) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 30px 20px 30px;
    @media (max-height: 500px) {
        height: 100%;
    }
        .resetpsw_sub_block{
            @include width(600px);
            margin: 0 auto;
            @media (max-width: 991px) {
                @include width(300px);
            }
            .header{
                padding-bottom: 77px;
                img{
                    display: block;
                    max-width: 310px;
                    width: 100%;
                }
            }
            .resetpsw_form_block{
                h4{
                    font-family: raleway_bold;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    color: #000000;
                    text-align: left;
                    text-transform: capitalize;
                    padding: 0 0 24px 15px
                }
                .resetpsw_form{
                    @include width(300px);
                    span.ant-input-affix-wrapper.ant-input-password {
                        background: #FFFFFF;
                        box-shadow: 0px 0px 25px rgb(0 0 0 / 15%) !important;
                        border-radius: 5px;
                        border: none;
                        padding: 9px 20px 9px 15px;
                        input{
                            font-family: raleway_bold;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 16px;
                            text-transform: capitalize;
                            color: rgba(73, 85, 104, 0.8);
                            &::placeholder{
                                font-family: raleway_medium;
                                color: rgba(73, 85, 104, 0.6);
                        }
                      
                    }
                    &:hover, &:focus{
                        box-shadow: 0px 0px 25px rgb(0 0 0 / 15%) !important;
                    }
                }
                .ant-form-item-explain-error{
                    padding: 8px 0 5px 15px;
                }
                .ant-row.ant-form-item:not(:first-child){
                    margin: 25px 0 0;
                }
                .form_buttons{
                    display: flex;
                    justify-content: flex-end;
                    margin: 45px 0 0;
                    button.btn {
                        min-width: unset;
                        max-width: 105px;
                        width: 100%;
                        border-radius: 30px;
                        padding: 14px 10px 13px;
                        text-transform: uppercase;
                        font-family: raleway_medium;
                        font-weight: 600;
                        letter-spacing: 0.8px;
                        &.btn_default{
                            background: #CBCBCB;
                            color: #3E4A58;
                        }
                    }
                }
            }
            .successfully_msg{
                font-family: raleway_bold;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: rgba(73, 85, 104, 0.8);
                @include width(450px);
                padding: 0 0 20px;
            }
            }
        }

}
.ant-form-item-explain-error{
    font-family: montserrat_semibold;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.2px;
    color: #C00C2D !important;
    padding-top: 5px;
}
.ant-form-item-explain{
    min-height: unset;
}
