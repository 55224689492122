.input_details{
   font-family: montserrat_semibold;
   font-weight: 500;
   font-size: 16px;
   line-height: 20px;                                   
   letter-spacing: 0.3125px;
   color: $gray_label;
   border: none;
   margin: 11px 0 0 0;
}
.forms_details h3{
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;    
    letter-spacing: 0.3125px;
    color: rgba(89, 89, 89, 0.5);
    margin: 0;
}

.physiciandetail{
    width: calc(100% - 25rem);

     .physiciandetail_nav{
        @extend .d_flex;   
        @extend .justify_content_between; 
        @extend .align_items_center;
        flex-wrap: wrap;
        padding: 13px 45px 13px 75px;
        background: rgba(154, 176, 189, 0.19);

        @include media992{
            padding:13px 17px 13px 20px;

           }

        .physiciandetail_nav--block1{
            padding: 5px 5px 4px;
            h2{
                @extend .montserrat_bold;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.3125px;
                text-transform: uppercase;
                color: $black;

            }
        }
        .physiciandetail_nav--block2{
            text-align: right;        
            padding: 5px 5px 4px;
            .physiciandetail_nav--block2_text h2{
                margin: 0;
                @extend .montserrat_regular;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                text-align: right;
                letter-spacing: 0.3125px;
                color: $text_blue;
            }
        }
    }

    .physician_detail_data{
        margin: 0;
        max-width: 830px;
        width: 100%;
        padding: 36px 20px 20px 80px;
        @media (max-width: 1024px){
            padding-left: 20px;
        }
        .physician_detail_data_info{
         @extend .d_flex;
         @media (max-width: 991px){
            flex-direction: column-reverse;
        }

            .physician_detail_data_name{
              .forms_details{
                #physician_first_name{
                     @extend .input_details;
                }
            }
        }
        
        .edit{
            padding: 3px 19px;
            @extend .montserrat_semibold;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: #FFFFFF;
            min-width: 61px;
            background: #C00C2D;
            border-radius: 3px;
        }
        }
        .physician_email{
            @extend .input_details;
        }
         .email_div{
            margin-top: -32px;
            @media (max-width: 991px){
                margin-top: 0;
            }
        }
         /************************************************************/
         .bio{
             max-width: 610px;
             margin-bottom: 0;
            @media (max-width: 991px){
                max-width: 100%;
            }
              textarea{
                width: 100%;
                height: 236px;
                margin: 0;
             }
            div h5{
                margin-bottom: 10px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;                            
                letter-spacing: 0.3125px;
                color: rgba(89, 89, 89, 0.5);

            }
            }
    }
}

.comman-ps_ulBlock{
    @extend .input_details;
    margin-top: 10px;
    &.contact_email{
        margin-top: 8px;
    }
    ul{
        margin: 0;
        list-style: none;

        li{
            position: relative;
            padding: 0 0 8px 10px;
            &::before{
                content: "";
                position: absolute;
                background-color: #3E4A58;
                width: 4px;
                height: 4px;
                left: 0;
                top: 8px;
            }
            &:last-child{
                padding-bottom: 0;
            }
            &:empty::before{
                display: none;
            }
        }
    }
 }

.physicianDetailsPop{
    max-width: 956px !important;
    width: 100% !important;
    max-height: 100vh !important;
    background-color: unset;
    box-shadow: unset;
    .ant-modal-content{
        margin: 20px 0;
    }
}

.physician_detail{
    h4{
        @extend .montserrat_regular;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: capitalize;
        color: #25282C;
        margin-bottom: 30px;
    }
}
// physician detail popup
.main_physician_details{
    max-width: 670px;
    width: 100%;
    display: flex;
    padding: 0 10px 0 100px;
    align-items: flex-start;
    .profile_label{
        font-weight: 600;
    }
    .form_row{
        margin-bottom: 24px;
    }
    .form_group.profile {
        max-width: 120px;
        width: 100%;
        padding: 0 20px 0 0;
    }
    .form.form_group.details{
        width: calc(100% - 120px);
        padding-left: 20px;
        .forms_details_box{
            .clinic_data{
                width: 50%;
                &:first-child{
                    padding-right: 5px;
                }
                &:nth-child(2){
                    padding-left: 5px;
                }
            }
            .clinic_name_wrap{
                &:not(:last-child){
                    margin-bottom: 22px;
                }
                .clinic_data{
                    width: 100%;
                }
            }
            h3.forms_details{
                margin-bottom: 10px;
            }
            h3.forms_details, p.forms_details_text{
                @extend .montserrat_regular;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.3125px;
                color: #595959;
            }
            p.forms_details_text{
                font-size: 16px;
                @extend .montserrat_semibold;
                margin-bottom: 0;
            }
        }
    }
    .main_degrees_div{
        display: flex;
        p{
            padding-right: 5px;
        }
    }
    .main_bio{
        p.forms_details_text {
            margin-bottom: 30px !important;
        }
    }
    .main_list{
        display: flex;
        ul{
            list-style: none;
            margin: 0;
            padding-right: 5px;
            &:last-child{
                li{
                    padding-right: 5px;
                    &::after{
                        content: "";
                        position: absolute;
                        background-color: #fff;
                        height: 100%;
                        width: 10px;
                        right: 0;
                        top: 0;
                    }
                }
            }
            li{
                position: relative;
                @extend .montserrat_semibold;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.3125px;
                color: #595959;
                text-transform: uppercase;

            }
        }
    }
    .physician_btn{
        margin-top: 160px;
    }
    @media (max-width: 991px){
        &{
            .physician_btn{
                margin-top: 50px;
            }
        }
    }
    @media (max-width: 768px){
        padding: 0 10px;
        flex-direction: column;
        .form_group.profile{
            margin: 0 auto 20px;
            padding-right: 0;
        }
        .form.form_group.details{
            width: 100%;
            padding-left: 0;
        }
    }
}

// ps details popup
.physician_detail_data{
    .forms_details{
        margin-bottom: 0;
    }
    .physician_detail_data_name{
        margin-bottom: 24px;
    }
    .main_ps_details{
        display: flex;
        max-width: 475px;
        width: 100%;
        padding-right: 30px;
        @media (max-width: 1200px){
            padding-right: 10px;
        }
        .physician_detail_data_name{
            width: 50%;
            margin-bottom: 18px;
            &:first-child{
                padding-right: 10px;
            }
            &:nth-child(2){
                padding-left: 10px;
            }
        }
        @media (max-width: 991px){
            &{
                max-width: 100%;
                padding-right: 0;
            }
        }
    }
    .main_ps_profile {
        width: calc(100% - 475px);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-left: 30px;
        @media (max-width: 1200px){
            padding-left: 10px;
        }
        @media (max-width: 991px){
            justify-content: center;
            width: 100%;
            padding: 0 0 20px 0;
        }
        .physician_detail_data_pic{
            padding: 0 20px 10px 0;
            img{
                display: block;
                max-width: 100%;
                object-fit: cover;
                height: 100px;
                width: 100px;
                left: 831px;
                top: 211px;
                border-radius: 100%;
            }
        }
    }
    .main_psd_data {
        max-width: 420px;
        width: 100%;
        @media (max-width: 991px){
            &{
                max-width: 100%;
            }
        }
    }
    .psd_contact_info{
        display: flex;
        .physician_detail_data_name{
            width: 50%;
            &:first-child{
                padding-right: 10px;
            }
            &:nth-child(2){
                padding-left: 10px;
            }
        }
    }
    .bio{
        .psd_bio_txt{
            padding: 10px 0 30px 0;
            p{
                font-family: montserrat_semibold;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #3E4A58;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
}
.psd_link_block{
    a{
        @extend .montserrat_regular;
        @include width(200px);
        display: block;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.3125px;
        @media (max-width: 1200px) {
            max-width: 250px;
        }
    }
}
.physician_degress{
    text-transform: uppercase;
}
// edit physician
.update-comman_block{
    @include width(690px);
    padding: 38px 20px 20px 74px;
 .card{
        padding: 0 !important;
    }
    .clinic_form{
        padding: 0 30px 0 0;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .form_group.profile {
        padding-left: 20px;
        margin: 0;
        @include width(120px);
        @media (max-width: 768px) {
            margin: 0 auto 20px;
            padding-left: 0;
            max-width: 100px;
        }
    }
}

.calendar_div{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .sub_block{
 
        img{
            max-width: 20px;
            width: 100%;
            height: 24px;
            display: inline-block;
            margin-right: 2px;
        }
    }
    span{
        padding: 0 0 0 2px!important;
        display: inline-block;
    }
}

// new page
.ps-details_main_block{
    padding: 10px;
    width: calc(100% - 250px);
    margin-left: auto;
    @media (max-width: 1200px){
        width: 100%;
        margin: 0 auto;
    }
    @media (max-width: 768px){
       padding: 0;
    }
    .ps-details_container{
        @include width(795px);
        @media (max-width: 1200px){
            margin: 0 auto;
        }
        .ps-details_header_main{
            padding: 10px 20px;
            background: rgba(154, 176, 189, 0.19);
            border: 1px solid rgba(0, 0, 0, 0.2);
            @media (max-width: 768px){
                padding: 20px 10px;
                background: unset;
                border: unset;
            }
            @media (max-width: 350px){
               padding: 10px 0;
            }
        }
        .ps-details_header{
            @include width(650px);
            margin: 0 auto;
            @include flex;
            .ps-details_title{
                h2{
                    font-family: montserrat_bold;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.3125px;
                    text-transform: uppercase;
                    color: #000000;
                    padding-right: 10px;
                }
            }
            .ps-details_profile{
                display: flex;
                align-items: center;
            }
            .ps-details_name{
                width: calc(100% - 50px);
                padding-right: 16px;
                h4{
                    font-family: raleway_bold;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: right;
                    color: #495568;
                    text-transform: unset;
                    padding-bottom: 6px;
                }
                span{
                    display: block;
                    font-family: raleway_medium;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    text-align: right;
                    color: #7C7C7C;
                    @include width(150px);
                    margin-left: auto;
                }
            }
            .ps-details_picture{
                @include width(50px);
                height: 50px;
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            @media (max-width: 768px){
                &{
                    display: block;
                    .ps-details_title{
                        display: none;
                    }
                }
            }
            @media (max-width: 350px){
                &{
                   .ps-details_profile{
                        flex-direction: column-reverse;
                    .ps-details_name{
                        padding: 10px 0 0 0;
                        h4,span{
                            text-align: center;
                            margin: 0 auto;
                        }
                    }
                   }
                }
            }
        }
        .ps-details_biomain{
            @include width(666px);
            padding: 16px 10px 16px 74px;
            .ps-detail_bioheader{
                @include flex;
                h4{
                    font-family: raleway_bold;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    color: #495568;
                }
                .download-btn{
                    border: none;
                    background-color: unset;
                    padding: 0 0 0 10px;
                    img{
                        @include width(16px);
                        height: 20px;
                        display: block;
                    }
                }
            }
            .ps-details_biodetail{
                @include width(530px);
                padding: 18px 0 28px;
                p{
                    font-family: raleway_medium;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 13px;
                    color: #3E4A58;
                    &:not(:last-child){
                        padding-bottom: 15px;
                    }
                }
            }
            .year_experiance{
                @extend .comman_title_style;
                padding-bottom: 18px;
            }
            .ps-details_educationmain{
                &:not(:last-child){
                    margin-bottom: 18px;
                }
                h4{
                    @extend .comman_title_style;
                    text-transform: unset;
                    padding-bottom: 7px;
                }
                .ps-details_list{
                    margin-top: 0;
                    ul{
                        li{
                            font-family: raleway_medium;
                            font-weight: 500;
                            font-size: 11px;
                            line-height: 13px;
                            color: #3E4A58;
                            margin: 0 !important;
                            padding-left: 12px;
                            @include width(280px);
                            &::before{
                                top: 4px;
                            }
                            &:not(:last-child){
                                margin-bottom: 6px;
                            }
                        }
                    }

                }
            }
            @media (max-width: 768px) {
                &{
                    padding: 22px 25px 40px;
                    max-width: 100%;
                    background-color: rgba(229, 229, 229, 0.5);
                }
            }
        }
    }
}
.comman_title_style{
    font-family: raleway_bold;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #495568;
    display: block;
    text-align: left;
}