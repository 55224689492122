// Table
.table_responsive {
  overflow-x: auto;
  // min-height: 20rem;
  .table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    thead {
      background: rgba($purple, .37);
      width: 100%;
      @extend .text_capitalize;
      tr {
        td {
          vertical-align: top;
          @extend .fs_14;
          line-height: 2rem;
          color: $black;
          @extend .text_capitalize;
          @extend .montserrat_semibold;
          // @extend .white_space_nowrap;
          padding: 13px 10px 20px; 
          .arrow_up{
            width: 1.6rem;
            @extend .cursor_pointer;
            // @extend .ml_05;
              img{
                  width: .8rem;
                  @extend .img_object_center;
              }
          }
          @media (max-width: 1500px){
            font-size: 1.2rem;
          }
        }
      }
    }
    tbody{
        tr{
            td{
                @extend .fs_13;
                line-height: 2rem;
                color: $text_think;
                @extend .text_capitalize;
                font-family: 'montserrat_medium';
                padding: 26px 10px 18px; 
                border-bottom: 1px solid #ddd;
                // height: 75px;
                // min-height: 75px;    
                text-transform: unset;                           
                a{
                    color: $text_blue;
                }
                .actions_icon{
                    // width: 1.4rem; 
                    @extend .d_flex_center;
                    @extend .cursor_pointer;
                    img{
                      width: 14px;
                      height: 16px;
                        // @extend .img_object_center;
                    }
                }
                &.td_emailphone{
                  padding-bottom: 6px;
                    // padding-top: 20px;
                    span{
                      display: block;
                      &:empty{
                        padding: 7px;
                      }
                    }
                }
                &.td_icons{
                  padding-right: 30px;
                  @media (max-width: 1679px){
                    padding-right: 20px;
                  }
                }
            }
        }
    }
    &.clinic_data_list {
      min-width: 110rem;
      table-layout: fixed;
      td {
        &:nth-child(1) {
          width: 3%;
          padding-right: 0px;
          img{
            display: inline-block;
            vertical-align: top;
            max-width: 20px;
            margin: 0 auto;
          }
        }
        &:nth-child(2), &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4) {
          width: 18%;
          @media (max-width: 1679px){
            width: 23%;
          }
        }
        &:nth-child(5), &:nth-child(6) {
          width: 8%;
          @media (max-width: 1679px){
            width: 9%;
          }
        }
        &:nth-child(7) {
          width: 10%;
        }
        &:nth-child(8) {
          width: 11%;
        }
        &:nth-child(9){
          width: 9%;
          padding-right: 26px;
          text-align: right;
          @media (max-width: 1679px){
            width: 10%;
            padding-right: 10px;
          }
          @media (max-width: 1500px){
            width: 12%;
            padding-right: 10px;
          }
        }
        &:nth-child(5){
          text-align: center;
        }
      }
      &.physician{
        td{
          width: 180px;
          min-width: 180px;
          &:nth-child(1){
            width: 25px;
            min-width: 25px;
          }
          &:nth-child(2){
            width: 135px;
            min-width: 135px;
            @media (max-width: 1680px) {
              width: 155px;
              min-width: 155px;
            }
          }
          &:nth-child(3){
            width: 40px;
            min-width: 20px;
            padding: 26px 5px 18px;
            .ps-code{
              @include width(20px);
              height: 20px;
              display: block;
              position: relative;
              &:hover{
                .reg_code{
                  display: block;
                }
              }
              img{
              @include image;
              }
              .reg_code {
                display: none;
                position: absolute;
                max-width: 170px;
                min-width: 170px;            
                background: #F8F8F8;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
                border-radius: 5px;
                padding: 5px 10px 2px;
                top: -45px;
                left: -10px;
                p{
                  font-family: montserrat_medium;
                  font-weight: 500;
                  font-size: 9px;
                  line-height: 12px;
                  letter-spacing: 0.3125px;
                  color: #000000;
                  margin: 0;
                }
                span{
                  font-family: montserrat_semibold;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.3125px;
                  color: #000000;
                }
            }
            }
          }
          &:nth-child(4) {
            text-align: left;
            width: 240px;
            min-width: 240px;
          }
          &:nth-child(5){
            text-align: left;
            width: 190px;
            min-width: 190px;
            @media (max-width: 1680px) {
              width: 200px;
              min-width: 200px;
            }
          }
          &:nth-child(6){
            min-width: 90px;
            width: 90px;
          }
         &:nth-child(8) {
            width: 130px;
            min-width: 130px;
          }
          &:nth-child(9){
            width: 140px;
            min-width: 140px;
            text-align: left;
            padding-right: 10px;
            @media (max-width: 1680px) {
              width: 190px;
              min-width: 190px;
            }
          }
          &:nth-child(7){
            text-align: left;
            width: 110px;
            min-width: 110px;
          }
          &:nth-child(10){
            width: 100px;
            min-width: 100px;
            padding-right: 26px;
            text-align: right;
            @media (max-width: 1680px) {
              width: 130px;
              min-width: 130px;
            }
          }
        }
      }
      &.patients_table{
        td{
          width: 180px;
          min-width: 180px;
          &:nth-child(1){
            width: 15px;
            min-width: 15px;
          }
          &:nth-child(2){
            padding-left: 5px !important;
            width: 80px;
            min-width: 80px;
          }
          &:nth-child(3) {
            width: 110px;
            min-width: 110px;
            @media (max-width: 1660px) {
              width: 150px;
              min-width: 150px;
            }
          }
          &:nth-child(4){
            width: 50px;
            min-width: 50px;
            text-align: center;
            @media (max-width: 1680px) {
              width: 60px;
              min-width: 60px;
            }
          }
          &:nth-child(5){
            width: 30px;
            min-width: 30px;
            text-align: center;
          }
          &:nth-child(6) {
            text-align: left;
            width: 60px;
            min-width: 60px;
            @media (max-width: 1680px) {
              width: 70px;
              min-width: 70px;
            }
          }
          &:nth-child(7) {
            width: 70px;
            min-width: 70px;
            @media (max-width: 1680px) {
              width: 80px;
              min-width: 80px;
            }
          }
          &:nth-child(8){
            width: 110px;
            min-width: 110px;
            @media (max-width: 1670px) {
              width: 120px;
              min-width: 120px;
            }
          }
          &:nth-child(9){
            padding-right: 49px !important;
            text-align: right;
            width: 70px;
            min-width: 70px;
            @media (max-width: 1670px) {
              padding-right: 40px !important;
            }
          }
        }
      }
    }
    &.additionalmin_table{
      thead{
        td{
          background: #EBF3F8;
        }
      }
      tbody{
        tr{
          border-bottom: none;
          &:first-child{
            td{
              padding-top: 16px;
            }
          }
        }
      }
      td{
        font-family: montserrat_semibold;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        width: 100px;
        min-width: 100px;
        padding: 8px 5px;
        border-right: 2px solid #fff;
        border-bottom: none;
        span{
          display: block;
          font-size: 10px;
          line-height: 12px;
          color: #000000;
        }
        &:first-child{
          width: 140px;
          min-width: 140px;
        }
        &:last-child{ 
          width: 100px;
          min-width: 100px;
          text-align: right;
          padding-right: 12px;
          border: none;
        }
        &:nth-child(2){ 
          padding-left: 28px;
          width: 140px;
          min-width: 140px;
        }
        &:nth-child(3){ 
          padding-left: 16px;
          width: 140px;
          min-width: 140px;
          @media (max-width: 1500px) {
            width: 150px;
            min-width: 150px;
          }
        }
      }
    }
  }
}
span.actions_icon{
  margin: 0 !important;
  font-size: 12px;
  img{
    margin: 2px 3px 2px 2px !important;
    display: block;
  }
  &:last-child{
    img{
      margin-right: 0 !important;
    }
  }
}
.patients_table{
  thead tr td{
    padding: 11px 10px 12px !important; 
  }
}
.patients_loader{
  top: 41% !important;
}