.ReactModal__Overlay--after-open {
  background-color: transparent !important;
}
.ant-modal{
  padding-bottom: 0px !important;
  .ant-modal-content{
    border-radius: 15px;
  }
}
.modal_dialog {
  border-radius: 1rem !important;
}

//.ant-modal-mask{
//  background: transparent !important;
//}
.ant-modal-close-x{
  color: black;
}
.lodar_modal{
  min-height: 796px;
}
.modal {
  max-height: 90vh;
  padding-bottom: 3.7rem;
  margin: 0 auto;
  position: relative;
  background: #fff;
  border-radius: 1.5rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.13);
  max-width: 956px;
  width: 100% !important;

  & > button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: 0;
    background-image: url(./../images/icon_cross.svg);
    background-size: 1.2rem 1.2rem;
    background-repeat:  no-repeat;
    height: 1.2rem;
    width: 1.2rem;
    font-size: 0;
  }
  // &.addPhy {
 

    .custom_modal {
      padding: 0 10px 20px 10px;
      overflow-y: auto;
      max-height: 80vh;
      @media (max-width: 768px){
        padding: 0;
      }
      &::-webkit-scrollbar {
        width: 3px;
        height: 4px;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-track {
        background: transparent; 
      }
      &::-webkit-scrollbar-thumb {
        background: #C4C4C4; 
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #C4C4C4 ; 
      }
      .form {
        display: flex;
        max-width: 674px;
        width: 100%;
        margin: 0 auto;
        .form_heading {
          max-width: 434px;
          width: 100%;
        }
        .form_group.profile {
          max-width: 120px;
          width: 100%;
          padding: 0 20px 0 0;
        }
        .physician_form {
          width: calc(100% - 120px);
          padding-left: 20px;
          .form_action{
            margin-top: 46px;
          }
          .form_group {
            margin-bottom: 18px;
            label {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.3125px;
              color: #595959;
            }
          }
        }
        .form_row, .form_group {
          max-width: 434px;
          width: 100%;
      }
      @media (max-width: 768px){
        &{
          .form_group.profile, .authorized_profile {
            margin: 0 auto !important;
            padding: 0 10px 20px !important;
        }
        }
      }
      @media (max-width: 580px){
        &{
          flex-direction: column;
          .physician_form .form_row, .physician_form .form_group{
            max-width: 100% !important;
          }
          .physician_form, .authorized_form_model{
            width: 100% !important;
            padding-left: 0 !important;
          }
        }
      }
      }
      .authorized_form{
        max-width: 700px;
        .user_profile_pic{
          text-align: center;
          margin-bottom: 10px;
          .addnew{
            right: 6px;
          }
        }
        .authorized_profile{
          max-width: 140px;
          padding-right: 25px;
          padding: 0 25px 0 0;
        }
        .authorized_form_model {
          width: calc(100% - 140px);
          padding-left: 25px;
        }
        .user_profile{
          label {
            text-align: center;
          }
        }
        @media (max-width: 768px){
          &{
            .authorized_form_model{
              h3{
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  // }
  &.errPop {
    max-width: 40.9rem;

    & > button{
      display: none;
    }
    // .custom_modal {
    //   padding-left: 4.2rem;
    //   padding-right: 4.2rem;
    // }
    h3{
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
    }
    p{
      @extend .fs_12;
    }
    .otpMsg{
      .form_row{
        .form_group{
          width: 41px;
          &:not(:last-child){
            margin-right: .9rem;
          }
          input{
            font-size: 40px;
            line-height: 42px;
            font-weight: 600;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
          }
        }
      }
    }
    .form_action{
      margin-bottom: 0;
    }
  }
  .form_heading {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;  
    padding: 0 20px 0 80px;
    @media (max-width: 768px) {
      &{
        padding: 0;
      }
    }
  }
}
.modal.errorPop{
  max-width: 400px !important;
}

.modal.errorPop {
.ant-modal-content{
  border-radius: 1rem ;
}
}

.modal.primaryPop{
  max-width: 553px !important;
  width: 100% !important;
  text-align: center;
  &.changemin-modal{
    max-width: 523px !important;
    width: 100% !important;
  }
}

//.ant-modal-mask{
//  background: transparent !important;
//}
.ant-modal-close{
  top: 15px !important;
  right: 12px !important;
}
.ant-modal-close-x{
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}
.ant-modal-body {
  padding: 34px 9px 24px 24px !important;
  @media (max-width: 768px){
    &{
      padding:  20px 15px!important;
    }
  }
}
.ant-modal{
  top: 0 !important;
}
.ant-modal-wrap {
  margin: 0 !important;
  padding: 20px !important;
  height: 100vh !important;
  width: 100vw !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

// delete popup
.ant-modal.modal.deletePop{
    max-width: 410px !important;
    width: 100% !important;
    .ant-modal-body{
      padding: 22px 20px 28px !important;
    }
  }
  .main_delete_modal{
    max-width: 324px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 680px){
      .form_action {
        margin: 0 auto !important;
      }
    }
    &.disable_physician_modal, &.enable_physician_modal{
      max-width: 350px;
      .delete_content{
        padding-bottom: 18px;
      }
      .reversed{
        color: #495568;
      }
    }
    &.enable_physician_modal{
      .proceed{
        padding-top: 16px;
      }
      .reversed{
        @include width(340px);
        margin: 0 auto;
      }
    }
    h4{
      @extend .montserrat_regular;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
      padding-bottom: 16px;
      margin: 0;
    }
    .delete_content{
      padding-bottom: 26px;
      p{
        @extend .montserrat_regular;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #495568;
        margin: 0;
        &:not(:last-child){
          padding-bottom: 10px;
        }
      }
    }
    .reversed, .proceed{
      font-family: montserrat_semibold;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #000000;
      display: block;
    }
    .proceed{
      padding: 32px 0 28px;
    }
    .delete_modal_btn{
      margin: 0;
      .btn{
        margin: 5px 6px;
      }
    }
  }
  .modal{
    .modal_btn{
      margin: 0;
      flex-wrap: wrap;
        button{
          min-width: unset;
          width: 46%;
          margin: 5px 6px;
        }
    }
  }
  .main_comman_modal.authenticate_modal {
    @include width(410px);
    padding: 20px 10px 42px;
    .custom_modal {
      padding: 0;
    }
  }


// transfer patients popup
.transferPatient{
  max-width: 956px !important;
  width: 100% !important;
  .ant-modal-body{
    padding: 36px 20px !important;
    .main_transfer_patients{
      h4{
        @extend .montserrat_regular;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: capitalize;
        color: #25282C;
        margin-bottom: 32px;
      }
      .transfer_form_grup{
        max-width: 438px;
        width: 100%;
        margin: 0 auto 28px;
        input{
          border: 2.5px solid #1784E9 !important;
        }
      }
      .main_select_patient{
        max-width: 524px;
        width: 100%;
        margin: 0 auto;
        h6{
          @extend .montserrat_regular;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          color: #595959;
          padding: 0 0 10px 16px;
          margin: 0;
        }
        .select_patient_box{
          padding: 14px 0;
          background: #F4F4F4;
          border-radius: 3px;
          height: 328px;
          overflow: hidden;
          .form_group.search{
            max-width: 310px;
            width: 100%;
            margin: 0 auto 16px;
            input{
              padding: 10px 33px;
              border-radius: 30px;
            }
            .serch_icon{
              position: absolute;
              left: 8px;
              top: 46%;
              transform: translate(0, -50%);
            }

            .search_icon{
              position: absolute;
              left: 16px;
              top: 46%;
              transform: translate(0, -50%);
            }
          }
          .select_all_patient_box{
            .main_select_all{
              padding: 0 18px;
            }
            .checkbox{
              span{
                width: 12px;
                height: 12px;
                background: #3E4A58;
                &::after{
                  content: "";
                  position: absolute;
                  display: none;
                }
              }
              label{
                @extend .montserrat_regular;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                color: #000000;
                margin-left: 8px;
              }
              & > [type="checkbox"]:checked + span:after{
                display: block;
                left: 3px;
                top: 0;
                width: 5px;
                height: 8px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                background-color: unset;
            }
            & > [type="checkbox"]:checked + span::before{
              display: none;
            }
            }
            .main_patients_details{
              display: flex;
              width: 100%;
              height: 230px;
              overflow-y: auto;
              .patients_details{
                width: 50%;
                h4:first-child {
                  padding-left: 23px;
              }
                h4{
                  @extend .montserrat_regular;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.3125px;
                  text-transform: capitalize;
                  color: #000000;
                  font-weight: 600;
                  text-align: left;
                  margin: 0;
                }
                &.patients_name{
                  padding-right: 10px;
                  h4{
                    padding-left: 18px;
                  }
                  .inner_div{
                    display: flex;
                    align-items: baseline;
                    margin-top: 7px;
                    .form_group{
                      margin: 0;
                    }
                   span{
                     padding-left: 7px;
                   }
                  }
                }
                &.patients_email{
                  padding-left: 10px;
                  .show_name{
                    margin-top: 7px;
                  }
                }
                  .show_name{
                    span{
                     @extend .montserrat_regular;
                     font-weight: 600;
                     font-size: 13px;
                     line-height: 20px;
                     letter-spacing: 0.3125px;
                     color: #636363;
                    }
                  }
              }
            }
          }
        }
      }
      .modal_btn {
        max-width: 350px;
        width: 100%;
        margin: 50px auto 0;
        @media(max-width: 480px){
          max-width: 100%;
        }
    }
    }
  }
}
// minutePopup
.addMinutePop{
  @include width(500px);
  h3{
    padding: 0 !important;
  }
  .ant-modal-body {
    padding: 20px !important;
    }
  .custom_modal{
    padding: 0;
    .form{
      max-width: 365px;
      width: 100%;
      margin: 0 auto;
      display: block;
    }
    .form_group {
      margin-top: 30px;
      text-align: center;
      label{
        text-align: left;
      }
    }
  }
}
.swal2-styled.swal2-confirm {
  line-height: 2rem !important;
  color: #ffffff !important;
  padding: 1.1rem 2.5rem !important;
  min-width: 10.4rem !important;
  background: #C00C2D !important;
  font-weight: 600;
  border-radius: 1rem;
  font-size: 1.4rem;
  margin-bottom: 19px;
}
.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
  padding: 18px;
}
.edit_form_group{
  max-width: 520px !important;
  .text_add {
    align-items: center;
    input{
      max-width: 434px;
      width: 100%;
    }
    span{
      position: unset;
      transform: unset;
      width: calc(100% - 434px);
      padding-left: 10px;
    }
    @media (max-width: 750px) {
        &{
          flex-wrap: wrap;
          span{
            width: 100%;
            padding-top: 4px;
          }
        }
    }
    @media (max-width: 580px){
      &{
        input{
            max-width: 100%;
          }
      }
    }
  }
}

.user_profile {
  &_pic {
    position: relative;
    & > img {
      height: 100px;
      width: 100px;
      border-radius: 100%;
      object-fit: cover;
    }
   

    .addnew {
      height: 2.6rem;
      width: 2.6rem;
      position: absolute;
      bottom: 4px;
      right: 2px;

      input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
        padding-left: 26px;
      }
    }
  }
}
.profile_label{
font-weight: 500;
font-size: 12px;
line-height: 14px;  
font-family: "Raleway_Medium",sans-serif;
text-align: center;
text-transform: capitalize;
color: #000000;
margin-top: 12px;
}
.update_physician_block {
  .form_row, .form_group{
    @include width(434px);
  }
}
// change minutes plan

.change-min_modal{
    max-width: 523px !important;
    width: 100% !important;
  &.main_delete_modal{
    max-width: 100%;
  }
  h5{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 18px;
  }
  .form_group{
    margin-bottom: 14px;
    label{
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.3125px;
      color: #595959;
      margin-bottom: 10px;
    }
    select{
      max-width: 186px;
      width: 100%;
      color: #000;
    }
    &.form_action{
      margin-bottom: 0;
      .btn{
        margin: 5px 8px ;
      }
    }
  }
  .delete_content{
    @include width(370px);
    margin: 0 auto 18px;
    padding: 0;
  }
  .proceed{
    padding: 0 0 22px;
    @media (max-width: 768px) {
      padding: 0;
    }
  }

}
.modal.primaryPop.canclemin-modal{
  max-width: 380px !important;
}
.switch_primary_box.signup_clinic.switch-primary_form{
  span.ant-input-affix-wrapper.ant-input-password{
    border: 1px solid #d9d9d9 !important;
  }
  input{
    width: 100% !important;
  }
}
.ant-modal.modal.addPhy.caregiversPop{
    max-width: 414px !important;
    width: 100% !important;
    .ant-modal-body{
      padding: 30px 10px 46px !important;
    }
    .caregivers-main_block{
      h5{
        font-family: montserrat_semibold;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        padding-bottom: 19px;
      }
      .caregivers-sub_block{
        min-height: 20vh;
        max-height: 40vh;
        overflow-y: auto;
        padding: 0 22px;
        &::-webkit-scrollbar {
          width: 2px;
        }
      &::-webkit-scrollbar-thumb {
          background: #BFC6CF;
      }
      &::-webkit-scrollbar-track {
          background: transparent;
      }
      }
      .caregivers-detail_block{
        padding: 22px 15px 20px;
        background: #F2F2F2;
        border-radius: 10px;
        display: flex;
        align-items: flex-start;
        &:not(:last-child){
          margin-bottom: 7px;
        }


        .profile_pic{
          @include width(50px);
          height: 50px;
          img{

            border-radius: 100%;
            @include image;
            height: 50px;
            width: 50px;
            object-fit: cover;
          }
        }
        .caregivers_detail{
          width: calc(100% - 50px);
          padding-left: 12px;
          h6{
            font-family: raleway_bold;
            font-weight: 600;
            @extend .caregiver_font_style;
            color: #000000;
            padding-bottom: 1px;
          }
          .comman_detail{
            font-family: raleway_medium;
            font-weight: 500;
            @extend .caregiver_font_style;
            display: inline-block;
            width: 100%;
            &:not(:last-child){
              padding-bottom: 2px;
            }
            img{
              width: 14px;
              height: 14px;
              display: inline-block;
              margin-right: 8px;
            }
            &.user_detail{
              img{
                width: 18px;
                height: 12px;
              }
            }
            &.mail{
              img{
                width: 15px;
                height: 12px;
              }
            }
          }
        }
        .checkbox_block{
          align-self: center;
          position: relative;
          display: block;
        }
        .checkbox_clone{
          width: 20px;
          height: 20px;
          border: 2px solid #457B9D;
          border-radius: 100%;
          position: relative;
          z-index: 1;
          background: #457B9D;
          display: none;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            display: block;
            top: 44%;
            width: 6px;
            height: 10px;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            border: solid white;
            background-color: unset;
            border-width: 0 2px 2px 0 !important;
        }
        }
        @media (max-width: 500px) {
          &{
            flex-direction: column;
            padding: 15px;
            .profile_pic{
              margin-bottom: 7px;
            }
            .caregivers_detail{
              padding-left: 0;
              width: 100%;
            }
            .checkbox_block{
              margin: 5px auto 0 0;
            }
          }
        }
        &.selected_caregiver{
          background: rgba(69, 123, 157, 0.17) !important;
          .checkbox_clone{
            display: inline-block;
          }
        }
      }
    }
}
.caregiver_font_style{
  font-size: 12.5px;
  line-height: 15px;
}

.show_name{
  .pl_label,.pl_label_2{
   @extend .montserrat_regular;
   font-weight: 600;
   font-size: 13px;
   line-height: 20px;
   letter-spacing: 0.3125px;
   color: #636363;
  }
  .pl_label{
    padding-left: 7px;
  }
}

// authorizedPop
.sub_appoinment_block{
  max-height: 70vh;
  overflow-y: auto;
  .react-datepicker{
    font-size: 10px !important;
    font-family: "montserrat_medium",sans-serif;
  }
  .react-datepicker__current-month{
    font-size: 12px !important;
    font-family: "montserrat_medium",sans-serif;
  }
  .react-datepicker__day-name,.react-datepicker__day, .react-datepicker__time-name{
    width: 30px !important;
    @media (max-width: 680px) {
      width: 20px !important;
    }
  }
}

.addappoinment_block{
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  .date_block{
    position: relative;
    z-index: 99;
    &::after{
      content: "";
      background: url(../images/calendar-icon.png) no-repeat;
      background-size: contain;
      width: 14px;
      height: 14px;
      display: block;
      top: 50%;
      position: absolute;
      right: 7px;
      transform: translate(0, -50%);
      cursor: pointer;
      z-index: 1;
    }
  }
}
.appoinment_time{
  max-width: 330px;
  width: 100%;
  margin-bottom: 27px;
  .form_group{
    margin: 0;
    width: 50%;
    input{
      border: none;
      background: rgba(229, 229, 229, 0.5);
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #3E4A58;
      padding: 10px 24px 10px 10px;
      &::placeholder{
        font-weight: 500;
        font-family: montserrat_medium;
      }
    }
    &:first-child{
      padding-right: 10px;
      margin: 0;
    }
    &:nth-child(2){
      padding-left: 10px;
      margin: 0;
    }
    span{
      display: inline-block;
    }
    .time_block{
      display: flex;
      width: 100%;
      align-items: center;
      p{
        @extend .montserrat_regular;
        margin: 0;
        padding-left: 6px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #3E4A58;
      }
      .pst{
        font-family: montserrat_semibold;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #3E4A58;
        padding-left: 6px;
      }
    }
    @media (max-width: 600px) {
      &{
        &:first-child{
          padding: 0 0 15px;
        }
        &:nth-child(2){
          padding-left: 0;
        }
      }
    }
  }
}
.main_call_length{
  margin-bottom: 28px;
  p{
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #495568;
    padding-bottom: 4px;
    margin: 0;
  }
}
.radio_buttons_block{
  display: flex;
  align-items: center;
  .min_block{
    @extend .montserrat_regular;
    display: block;
    cursor: pointer;
    position: relative;
    &:first-child{
      padding-right: 16px;
      padding-left: 25px;
    }
    &:nth-child(2){
      padding-left: 25px;
    }
    label{
      font-family: montserrat_medium;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-transform: lowercase;
      color: #3E4A58;
    }
    input{
      position: absolute;
      opacity: 0;
      cursor: pointer;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      height: 16px;
      width: 16px;
      z-index: 99;
    }
    .check {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      height: 16px;
      width: 16px;
      background-color: #457B9D;
      border-radius: 100%;
      &::after{
        content: "";
        position: absolute;
        display: none;
      }
    }
    input:checked ~ .check{
      background-color: #457B9D;
    }
    input:checked ~ .check::after{
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
    }
  }
}
.main_note_block{
  margin-bottom: 32px;
  textarea{
    background: rgba(229, 229, 229, 0.5);
    border-radius: 10px;
    border: none;
    resize: none;
    height: 80px;
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #7C7C7C;
    padding: 8px;
    &::placeholder{
      font-size: 12px;
      line-height: 15px;
      color: #7C7C7C;
    }
  }
  span{
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    display: block;
  }
}
.select_patient_block{
  h6{
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #495568;
    margin-bottom: 14px;
  }
  .current_patient_box{
    height: 400px;
    overflow: hidden;
    .current_patient_header{
      @include flex;
      flex-wrap: wrap;
      padding: 5px 27px 5px 7px;
      background-color: #457B9D;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      @media (max-width: 991px){
        padding-right: 10px;
      }
      .form_group.search{
        max-width: 200px;
        margin: 0;
        padding: 5px;
        input {
          padding: 5px 20px 5px 33px !important;
          border-radius: 30px !important;
          min-height: unset !important;
          background: #FFFFFF;
          border: 1.5px solid #DEDEDE;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          color: #3e4a58;
          font-weight: 500;
          @extend .montserrat_medium;
          &::placeholder{
            @extend .montserrat_medium;
          }
        }
      }
      h6{
        @extend .montserrat_regular;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.9px;
        color: #FFFFFF;
        padding: 5px;
      }
    }
    .main_cp_list{
      height: 342px;
      overflow-y: auto;
      position: relative;
      background: rgba(229, 229, 229, 0.5);
      .main_cp_list_col{
        padding: 7px 20px 9px 10px;
        background-color: #DADADA;
        filter: drop-shadow(4px 2px 12px rgba(0, 0, 0, 0.1));
        @include flex;
        flex-wrap: wrap;
        @media (max-width: 991px){
          padding-right: 10px;
        }
        &:nth-child(2n){
          background-color: #F8F8F8;
        }
        .cp_profile_block {
          display: flex;
          width: 50%;
          padding: 5px 10px;
          .profile {
            max-width: 24px;
            width: 100%;
            height: 24px;
            img{
              @include image;
              height: 100%;
              object-fit: cover;
              border: 1px solid #FFFFFF;
              box-sizing: border-box;
              filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
              border-radius: 100%;
            }
          }
          span{
            @extend .montserrat_regular;
            display: block;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.9px;
            color: #2D2D2D;
            padding-left: 8px;
          }
        }
        .cp_checkbox{
          padding: 5px 10px;
          .checkbox{
            margin: 0;
            [type=checkbox] {
              height: 20px;
              width: 20px;
              border-radius: 100%;
              cursor: pointer;
            }
            span{
              width: 20px;
              height: 20px;
              border: 2px solid #3E4A58;
              border-radius: 100%;
              &::after{
                content: "";
                position: absolute;
                display: block;
                top: 44%;
                width: 6px;
                height: 10px;
                left: 50%;

                transform: translate(-50%, -50%) rotate(
                                45deg);
                background-color: unset;
              }
            }
            & > [type="checkbox"]:checked + span{
              background: #3E4A58;
            }
            & > [type="checkbox"]:checked + span:after{
              border: solid white;
              background-color: unset;
              border-width: 0 2px 2px 0 !important;
            }
            & > [type="checkbox"]:checked + span::before{
              display: none;
            }
          }
        }
      }
      .srv-validation-message{
        padding: 10px;
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #BFC6CF;
      }
      &::-webkit-scrollbar-thumb {
        background: #3E4A58;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #C4C4C4 ;
      }
    }
  }
}
.appoinment_btn{
  max-width: 324px;
  width: 100%;
  margin: 40px auto 0 !important;
  @media (max-width: 991px) {
    margin: 20px auto 0;
    max-width: 100%;
  }
  .btn{
    margin: 5px 7px;
  }
}
.radio_buttons_block{
  padding: 5px 30px 5px 0;
}