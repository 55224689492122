.psd_link_block{
        a{
            color: #3079EF;  
            font-family: "montserrat_medium",sans-serif;   
            font-weight: 500;
            @media (max-width: 680px) {
                max-width: 100% !important;
                text-align: left;
            }
        }
}
.update-comman_block {
    display: flex;
    .update_patient_profile{
        max-width: 120px;
        width: 100%;
        padding-left: 20px;
    }
    .update_patient_model{
        width: calc(100% - 120px);
        padding-right: 34px;
    }
    .form_action{
        margin-top: 36px;
        @media (max-width: 600px) {
            &{
                flex-direction: column;
                margin-top: 20px;
                .btn {
                    margin: 5px 0;
                }
            }
        }
    }
    .form_control{
        border: 2px solid #DEDEDE;
        height: 100%;
        min-height: unset;
        padding: 10px;
    }
    .form_row{
        @media (max-width: 768px) {
            &{
                flex-direction: column;
                .form_group {
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
    .fill {
        min-height: 4.2rem;
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }
    @media (max-width: 991px) {
        &{
            padding: 20px;
        }
    }
    @media (max-width: 768px) {
        &{
            flex-direction: column-reverse;
            .update_patient_profile{
                max-width: 100px;
                width: 100%;
                margin: 0 auto 20px;
                padding-left: 0;
            }
            .update_patient_model{
                width: 100%;
                padding-right: 0;
            }
        }
    }
    &.update_physician_block {
        @include width(710px);
        select.form_control{
            height: auto;
        }
        .clinic_form {
            padding: 0;
            width: 100%;
        }
        .main_physician_row{
            @include flex;
            .sub_form_row{
                @include width(454px);
                padding-right: 20px;
            }
            .form_group.profile{
                padding-left: 20px;
                width: 100%;
                max-width: 120px;
            }
            .addnew{
                max-width: 26px;
                width: 26px;
                height: 26px;
                img{
                    @include image;
                    height: 100%;
                }
            }
            .profile_label{
                font-family: raleway_medium;
            }
        }
        @media (max-width: 991px) {
            &{ 
                .main_physician_row{
                    flex-direction: column-reverse;
                    align-items: unset;
                    .form_group.profile{
                        padding-left: 0;
                        max-width: 100px !important;
                        margin: 0 auto 20px;
                    }
                    .sub_form_row{
                        @include width(100%);
                        padding-right: 0;
                        .form_group {
                            max-width: 100%;
                        }
                    }
                }
                .form_group, .edit_form_group, .edit_form_group input {
                    max-width: 100% !important;
                }
                .form_row{
                    &{
                    max-width: 100%;
                    flex-direction: column;
                    .form_group {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }            
                }
                .edit_form_group{
                    .text_add{
                        flex-direction: column;
                        span{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.add_patient_block{
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding: 5px 0 4px 30px;
    @media (max-width: 1300px) {
        padding-left: 0;
    }
    .btn{
        margin-left: 5px;
        padding: 11px 15px !important;
    }
}
.main_file_uploade{
    max-width: 42px;
    width: 42px;
    height: 42px;
    background: #495568;
    border-radius: 10px;
    padding: 9px;
    position: relative;
    margin-right: 5px;
    input[type="file"]{
        opacity: 0;
        cursor: pointer;
        font-size: 0;
        max-width: 42px;
        width: 100%;
        height: 42px;
        @include position(absolute);
    }
    img{
        display: block;
        width: 100%;
        height: 100%;
    }
    .btn-loader{
        text-align: center;
        .ant-spin-spinning{
            line-height: 1;
            padding-top: 5px;
        }
    }
}
.link_block{
    padding: 5px 17px 4px 0;
    display: block;
    margin-left: auto;
    @media (max-width: 1300px) {
        margin-left: unset;
    }
    @media (max-width: 768px) {
        padding-right: 20px;
    }
}
.add_comman_header{
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .psd_link_block{
        a{
            max-width: 210px;
        }
}
}

// patients details 
.patients_details_block{
    .psd_contact_info{
        .physician_detail_data_name{
            width: 100%;
            padding-right: 0 !important;
        }
    }
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow{
    display: none;
}

.btn-loader{
    .ant-spin-dot-item{
        background: white !important;
    }
}
.update_physician_block{
    padding-top: 20px;
}
.ps-notice{
    font-family: montserrat_semibold;
    padding-bottom: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3125px;
    color: #C00C2D;
    position: relative;
    a{
        display: inline-block;
        color: #C00C2D;
        text-decoration: underline;
        font-family: montserrat_bold;
        font-weight: 500;
    }
    &::before{
        content: '';
        position: absolute;
        background: url(../images/notice-icon.svg) no-repeat;
        width: 22px;
        height: 22px;
        left: -30px;
        top: 0;
    }
    @media (max-width: 991px) {
        padding-left: 30px;
        &::before{
            left: 0;
        }
    }
}

